<template>
    <v-container class="page-container" fill-height fluid grid-list-xl>
        <v-layout wrap id="configuration" v-if="isEverythingReady">
          <template v-if="payoutSessions.length > 0">
            <!-- Header -->
            <div class="w-100 mb-3 m-t--40">
                <p class="grey-700 fs-16 lh-24">Settling Payments > Payout >  <span class="bold-font">{{ id }}</span> </p>
            </div>
            <div class="w-100 flex-display flex-row justify-space-between align-center">
                <span class="page-title text-xs-left mb-0">Payout {{ id }}</span>

                <div>
                    <select class="input-select bg-blue-500 white--text"
                            id="billStatus"
                            name="billStatus"
                            v-model="status"
                            v-on:change="updateBillStatus()"
                    >
                        <template v-for="billStatus in billStatuses">
                            <option :value="billStatus">{{ billStatus }}</option>
                        </template>
                    </select>
                </div>
            </div>
            <div class="border-bottom-2px w-100 my-3"></div>
            <div class="w-100 flex-display flex-row justify-space-between ">
                <div class="flex-display flex-column justify-start mt-3">
                    <p class="m-b-20 fs-16 lh-24 grey-700">Amount Due</p>
                    <h1 class="blue-500 fs-48 bold-font">£{{ netDue }}</h1>
                </div>
                <div class="flex-display flex-column justify-start mt-3">
                    <p class="grey-700 fs-16 lh-28">Due to</p>
                    <p class="grey-900 fs-20 lh-28">{{ dueToName }}</p>
                    <p class="grey-900 fs-20 lh-28">{{ dueToEmail }}</p>
                </div>
                <div class="flex-display flex-column justify-start mt-3">
                    <p class="grey-700 fs-16 lh-28">Due From</p>
                    <p class="mb-0 grey-900 fs-20 lh-28">{{ dueFromName }}</p>
                </div>
                <div class="flex-display flex-column justify-start">
                    <div class="flex-display flex-row justify-space-between align-center m-b-10">
                        <label class="item-label col-4 m-0 p-0" for="billDate">Finalised date: </label>
                        <date-picker class="p-0"
                                     id="billDate"
                                     :date="bill_date"
                                     :org="true"
                                     :isMaxDateEnable="false"
                                     :enable-reset="true"
                                     placeholder=""
                                     v-on:valueUpdated="updateBillDate"
                                     v-on:saveDate="updateBillDate"

                        ></date-picker>
                    </div>
                    <div class="flex-display flex-row justify-space-between align-center m-b-10">
                        <label class="item-label col-4 m-0 p-0" for="dueDate">Due date: </label>
                        <date-picker class="p-0"
                                     id="dueDate"
                                     :date="due_date"
                                     :org="true"
                                     :isMaxDateEnable="false"
                                     :enable-reset="true"
                                     placeholder=""
                                     v-on:valueUpdated="updateDueDate"
                                     v-on:saveDate="updateDueDate"
                        ></date-picker>
                    </div>
                    <div class="flex-display flex-row justify-space-between align-center">
                        <label class="item-label col-4 m-0 p-0" for="paidDate">Date paid: </label>
                        <input v-if="status === billStatuses[0]"
                               v-model="date_paid"
                               disabled
                               class="input-text w-100"/>
                        <date-picker v-else
                                     class="p-0"
                                     id="paidDate"
                                     :date="date_paid"
                                     :org="true"
                                     :isMaxDateEnable="false"
                                     :enable-reset="true"
                                     placeholder=""
                                     v-on:valueUpdated="updatePaidDate"
                                     v-on:saveDate="updatePaidDate"
                        ></date-picker>
                    </div>
                </div>

            </div>

            <div class="w-100 bg-red-100 px-3 py-2 mt-4 mb-3"
                 v-if="status === billStatuses[0]"
            >
                <p class="mb-0 red-500 fs-23 lh-32">
                  <span class="bold-font">OUTSTANDING.</span> {{ dueToName }} is due £{{ netDue }}.
                </p>
            </div>
            <div class="w-100 bg-green-200 px-3 py-2 mt-4 mb-3"
                 v-else
            >
              <p class="mb-0 green-500 fs-23 lh-32">
                <span class="bold-font">PAID IN FULL. </span> {{ dueToName }} received £{{ netDue }} on {{ date_paid }}.
              </p>
            </div>
            <template>
                <table id="invoiceDetails" class="table border-1px mt-2">
                    <thead>
                    <tr>
                        <th class="text-left grey-700">Client</th>
                        <th class="text-left grey-700">Session Date</th>
                        <th class="text-left grey-700">Organization</th>
                        <th class="text-left grey-700">Claim Invoice Id</th>
                        <th class="text-left grey-700">Session Fee</th>
                        <th class="text-left grey-700">Therapist Fee</th>
                        <th class="text-left grey-700">Therapist Received</th>
                        <th class="text-left">Net due to therapist</th>
                        <th class="text-left">Net due to MOT</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="payoutSession in payoutSessions">
                        <tr>
                            <td>{{ payoutSession.client_name }}</td>
                            <td>{{ payoutSession.session_date }}</td>
                            <td>{{ payoutSession.org }}</td>
                            <td>{{ payoutSession.claim_invoice_id }}</td>
                            <td>{{ payoutSession.session_fee }}</td>
                            <td>{{ payoutSession.therapist_fee }}</td>
                            <td>{{ payoutSession.therapist_received }}</td>
                            <td>{{ payoutSession.due_to_psychologist }}</td>
                            <td>{{ payoutSession.due_to_mot }}</td>
                        </tr>
                    </template>
                    </tbody>
                    <tr class="height-15"></tr>
                    <tfoot>
                    <tr>
                        <td colspan="5"></td>
                        <td colspan="2" class="border-1px grey-900 bold-font text-right">Subtotal</td>
                        <td class="border-1px ">£{{ netDueToTherapist }}</td>
                        <td>£{{ netDueToMot }}</td>
                    </tr>
                    </tfoot>
                </table>
            </template>
          </template>
          <template v-else>
            <div class="w-100 h-100 flex-display flex-row justify-center align-center">
              <p>No record found!</p>
            </div>
          </template>
        </v-layout>
    </v-container>
</template>

<script>
import DatePicker from "../../components/common/DatePicker";
import therapyHelper from '../../helpers/app/therapyHelper'

export default {
    name: "BillDetail",
    components: {
        DatePicker
    },
    data() {
        return {
            isEverythingReady: false,
            billStatuses: [therapyHelper.paymentStatusInExternalSystem, therapyHelper.paymentStatusPaidToPsychologist],
            bill_date: null,
            due_date: null,
            date_paid: null,
            billId: null,
            payoutSessions: [],
            id: null,
            type: '',
            status: '',
            netDue: 0,
            netDueToTherapist: 0,
            netDueToMot: 0,
            dueToEmail: '',
            dueToName: '',
            dueFromName: '',
            dueFromEmail: '',
        }
    },
    mounted() {
        this.billId = this.$route.params.id;
        this.getBillsDetails();
    },
    methods: {
        getBillsDetails() {
            this.isEverythingReady = false;
            let loader = this.$loading.show()
            this.$http.get(this.url + 'bill/detail' , {
                params: {
                    id: this.billId,
                    timezone: this.timezone
                }
            }).then(response => {
                if (response.data.draft_id) {
                  this.id = response.data.draft_id;
                  this.type = response.data.type;
                  this.status = response.data.status;
                  this.netDue = response.data.amount_due;
                  this.netDueToMot = response.data.net_due_to_mot;
                  this.netDueToTherapist = response.data.net_due_to_therapist;
                  this.due_date = response.data.due_date;
                  this.date_paid = response.data.paid_date;
                  this.bill_date = response.data.bill_date;
                  this.dueToEmail = response.data.due_to.email;
                  this.dueToName = response.data.due_to.name;
                  this.dueFromEmail = response.data.due_from.email;
                  this.dueFromName = response.data.due_from.name;
                  this.payoutSessions = response.data.data;
                }
                this.isEverythingReady = true;
                loader.hide();
            }).catch(error => {
                loader.hide()
                this.$toastr('error', error.response.data.message, 'Error')
            })
        },
        updateBillDate(date,updateApiCheck = false) {
            this.bill_date = date;
            if(updateApiCheck){
              this.updateBillDetails('bill_date',this.bill_date);
            }
        },
        updateDueDate(date,updateApiCheck = false) {
            this.due_date = date;
          if(updateApiCheck) {
            this.updateBillDetails('due_date', this.due_date);
          }
        },
        updatePaidDate(date,updateApiCheck = false) {
            this.date_paid = date;
          if(updateApiCheck) {
            this.updateBillDetails('paid_date', this.date_paid);
          }
        },
        updateBillStatus(){
          this.updateBillDetails('status',this.status);
        },

      updateBillDetails(colName,data){
        let formData = {};
        let loader = this.$loading.show()
        formData = {
          bill_id: this.billId,
          column_name: colName,
          value : data,
          device_type: "web"
        };
        this.$http.put(this.url + "bill/detail/update", formData).then((response) => {
          loader.hide();
          this.getBillsDetails();
          this.$toastr("success", response.data.message, "");
        }).catch((error) => {
          loader.hide();
          this.$toastr("error", error.response.data.message, "");
        });
      }
    },
}
</script>

<style scoped>

.input-select {
    border: 2px solid #308BC3 !important;
}
#billDetails th:nth-child(-n+7){
    font-weight: normal;
    color: #757575;
    font-size:16px;
}

th:last-child,
td:last-child,
th:nth-last-child(2),
td:nth-last-child(2) {
    text-align: right;
    background-color: #F1F1F1;
    font-weight: bold;
}
tbody tr:last-child{
  border: 1px solid #D4D4D4 !important;

}

</style>
